import React, { useRef } from "react"
import { Link } from "gatsby"
import ParallaxImage from "./parallaxImage"
import useMove from "../../hooks/useMove"

function PageList({ breadcrumb }) {
  return (
    <ul className="page-list">
      <li>
        <Link to="/">Home</Link>
      </li>
      {breadcrumb.map((item, i) => (
        <li key={i}>
          {item.to && (<Link to={item.to}>{item.title}</Link>)}
          {!item.to && item.title}
        </li>
      ))}

    </ul>
  );
}

function InnerHero({ title, description = null, breadcrumb = null }) {
  const { pageX, pageY, handleMouseMove } = useMove()
  const heroRef = useRef()

  return (
    <section
      className="inner-hero bg_img"
      ref={heroRef}
      onMouseMove={handleMouseMove}
    >
      <div className="shape position-absolute">
        <img src="/elements/inner-hero/shape.png" alt="image" />
      </div>
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-15}
        heroRef={heroRef}
        name={"/elements/inner-hero/el-1.png"}
        classes={"el-1 position-absolute"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-25}
        heroRef={heroRef}
        name={"/elements/inner-hero/el-2.png"}
        classes={"el-2 position-absolute"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-18}
        heroRef={heroRef}
        name={"/elements/inner-hero/el-3.png"}
        classes={"el-3 position-absolute"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-10}
        heroRef={heroRef}
        name={"/elements/inner-hero/el-4.png"}
        classes={"el-4 position-absolute"}
      />
      <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-15}
        heroRef={heroRef}
        name={"/elements/inner-hero/el-5.png"}
        classes={"el-5 position-absolute"}
      />
      {/* <ParallaxImage
        pX={pageX}
        pY={pageY}
        movement={-25}
        heroRef={heroRef}
        name={"/elements/inner-hero/el-6.png"}
        classes={"el-6 position-absolute"}
      /> */}

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="page-title">{title}</h2>
            {
              description && (<p className="page-description">{description}</p>)
            }
            {breadcrumb && (<PageList breadcrumb={breadcrumb} />)}
            {!breadcrumb && (
              <ul className="page-list">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>{title}</li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default InnerHero
